<template>
  <Containers class="w-full" :status="status" @funEnd='funEnd' :active='4' >

    <div class="w">
      <!-- banner开始 -->
      <div class="w">
        <banner class="w-full" title="SHOP" ></banner>
      </div>
      <!-- banner结束 -->

      <!-- 招牌产品开始 -->
      <div class="shops w p-0 m-0" >
        <headlines class="headlines font-XQ" title='门店分布' english="STORE DISTRIBUTION" color="#1E456C" ></headlines>
        <div class=" mb-80 pc-show">
          <a-row :gutter="[24,24]">
            <a-col :sm="12" :md="12" :lg="6"  class=" text-blue m-0 cursor-pointer" v-for="(item,index) in allShop" :key="index">
              <div class="shop-name ell wow fadeInUp" @click="toDetail(item.objectId)">{{item.name}}</div>
            </a-col>
          </a-row>
        </div>
        <a-row class="shops-container">
          <a-col :sm="24" :md="24" :lg="12"  class="shops-container-item text-blue m-0 cursor-pointer grid gap-y-10 bg-white" v-for="(item,index) in shop" :key="index" @click="toDetail(item.objectId)">
            <div class="picture w-full" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
            <div class="shop-name ell">{{item.name}}</div>
            <div class="address text-14 ell pl-48 pb-20">{{item.address}}</div>
          </a-col>
        </a-row>
        <pagination
          v-if="pageNum !== 0"
          class="pagination w-full"
          :show-jumper="true"
          :total="pageNum"
          :currentPage="page"
          :pagerCount="5"
          @current-change="currentChange"
        />
      </div>
      <!-- 招牌产品结束 -->

    </div>
  </Containers>
</template>

<script>
import { WOW } from 'wowjs'

import headlines from '@/components/headlines'
import banner from '@/components/banner'
import pagination from '@/components/pagination'

export default {
  metaInfo: {
    title: '门店分布_双美豆沙牛乳官方网站',
    meta: [
      {
        name: 'keywords',
        content: '双美豆沙牛乳门店分布_双美豆沙牛乳官网'
      },
      {
        name: 'description',
        content: '本页面为您提供双美豆沙牛乳门店分布，包含双美豆沙牛乳旗下各种类型门店等相关信息'
      }
    ]
  },
  data () {
    return {
      status: 'success',
      page: 1,
      pageNum: 0,
      num: 0,
      shop: [], // 页面数据
      allShop: [], // 全部店铺名
    }
  },
  mounted () {
    this.getInit()
  },
  components: {
    headlines,
    banner,
    pagination
  },
  computed: {
  },
  watch: {
    page () {
      this.getShop()
    }
  },
  methods: {
    async getInit () {
      try {
        await this.getShop()
        await this.getAllShopName()
        setTimeout(() => {
          this.$nextTick(() => {
            // 在dom渲染完后,再执行动画
            const wow = new WOW({
              live: false
            })
            wow.init()
          })
        }, 0)
      } catch (e) {
        this.status = 'error'
      }
    },
    funEnd () {
      // setTimeout(() => {
      //   this.status = 'success'
      //   this.$nextTick(() => {
      //     // 在dom渲染完后,再执行动画
      //     const wow = new WOW({
      //       live: false
      //     })
      //     wow.init()
      //   })
      // }, 300)
    },

    async getShop () {
      let list = await this.$axios.get('/classes/shopList', {
        params: {
          count: 1,
          order: '-createdAt',
          limit: 10,
          skip: (this.page - 1) * 10,
        }
      })
      this.shop = list.results
      this.num = list.count
      this.pageNum = Math.ceil(list.count / 10)
    },
    async getAllShopName () {
      let list = await this.$axios.get('/classes/shopList', {
        params: {
          order: '-createdAt',
          keys: 'objectId,name',
          limit: this.num,
        }
      })
      this.allShop = list.results
    },
    currentChange (page, old) {
      this.page = page
    },
    toDetail (id) {
      this.$push({
        path: '/shop-detail',
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/mixin1700.scss';

.shops{
  padding: 80px 140px 130px !important;
  background-color: #FCFAF0;
  @include hamburger {
    padding: 12px !important;
  }

  .headlines{
    margin-bottom: 80px;
    @include hamburger {
      margin-bottom: 24px;
    }
  }
  .shops-container{
    // padding: 80px 0;
  }
  .shops-container-item{
    margin-bottom: 32px !important;
    @include hamburger {
      margin-bottom: 15px !important;
    }
    &:hover{
      color: #CA4938 !important;
    }
    .picture{
      height: 440px;
      @include hamburger {
        height: 200px;
      }
    }
  }
  .pagination{
    margin-top: 80px;
    @include hamburger {
      margin-top: 0px;
    }
  }
}
.shop-name{
  position: relative;
  padding-left: 65px;

  &::before{
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 48px;
    top: 50%;
    transform: translateY(-50%);
    /* 2 */
    border: 2px solid #CA4938;
    border-radius: 50%;
    box-sizing: border-box
  }
  @include hamburger {
    padding-left: 35px;
    &::before{
      left: 15px;
    }
  }
}
.address{
  @include hamburger {
    padding-left: 15px;
    padding-bottom: 10px;
  }
}
</style>
